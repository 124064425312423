// category
let category = document.querySelector('.category-swiper');

if(category){
	const swiper = new Swiper('.category-swiper', {
	    slidesPerView: "auto",
	    spaceBetween: 32,
	    navigation: {
        	nextEl: ".swiper-button-next",
        	prevEl: ".swiper-button-prev",
      	},
	});
}
