// navigation mob
let btnMenu = document.querySelector('.hamburger');

if(btnMenu){
	let menuMob = document.querySelector('.navigation-mobile'),
		overly = document.querySelector('.overly'),
		body = document.body;

	btnMenu.addEventListener('click', function(){
		if(btnMenu.classList.contains('is_active')){
			this.setAttribute('aria-expanded', false);

		} else {
			this.setAttribute('aria-expanded', true);
		}

		btnMenu.classList.toggle('is_active');
		menuMob.classList.toggle('open');
		overly.classList.toggle('open');
		body.classList.toggle('body_scroll');
	})

	overly.addEventListener('click', function(){
		this.classList.remove('open');
		btnMenu.classList.remove('is_active');
		menuMob.classList.remove('open');
		body.classList.remove('body_scroll');
		btnMenu.setAttribute('aria-expanded', false);
	});

	[...document.getElementsByClassName('menu-downmenu')].forEach(function(el){
		const menuDownmenuBtn = el.getElementsByClassName('downmenu-btn')[0];
		const menuDownmenu = el.getElementsByClassName('downmenu')[0];

			menuDownmenuBtn.addEventListener('click', function(){
				if(el.classList.contains('open')){
					this.setAttribute('aria-expanded', false);
				} else {
					this.setAttribute('aria-expanded', true);
				}

				el.classList.toggle('open');
			});
	});

	[...menuMob.getElementsByTagName('a')].forEach(function(el){
		el.addEventListener('click', function(){
			overly.classList.remove('open');
			btnMenu.classList.remove('is_active');
			menuMob.classList.remove('open');
			body.classList.remove('body_scroll');
			btnMenu.setAttribute('aria-expanded', false);
		});
	});

}